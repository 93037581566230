<template>
  <section class="vision-section">
      <b-container>
        <b-row>
          <b-col>
            <span id="vision" class="subheader"></span>
          </b-col>
        </b-row>
        <b-row id="">
          <b-col sm="12">
            <h2 data-aos="fade-up" data-aos-delay="300" data-aos-once="true">Discover Our Vision</h2>
          </b-col>
          <b-col>
            <div class="embed-container" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <iframe id="fullVideo" width="100%" height="600" src="https://www.youtube.com/embed/guBeDsSM5K8?autoplay=1&mute=1&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'vision'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vision-section {
  margin-bottom: 25px;
}
.container {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
}
h2 {
  margin-bottom: 25px;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  z-index: 1;
}
.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
