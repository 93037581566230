<template>
  <section class="contact-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
      <b-container>
        <b-row>
          <b-col>
            <span id="contact-us" class="subheader"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="">
            <b-col><h2>Contact Us</h2></b-col>
              <b-form id="contactUs" class="contactUs" name="contactUs" @submit="submitContact" v-if="showForm">
                <div class="form-header col-sm-12 ">
                  <p class="small"><i>All fields are required. </i></p>
                </div>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="firstName">First name</label>
                  <b-form-input id="firstName" v-model="form.firstName" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="lastName">Last name</label>
                  <b-form-input id="lastName"  v-model="form.lastName" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="email">Email</label>
                  <b-form-input id="email"  v-model="form.email" type="email" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="iam">I am</label>
                  <b-form-select id="iam"  class="form-control" v-model="form.iam" :options="options" required></b-form-select>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-12">
                  <label for="message">Message</label>
                  <b-form-textarea id="message"  v-model="form.message" placeholder="" required></b-form-textarea>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-12">
                  <b-form-checkbox id="optin"  v-model="form.optin" placeholder="" required>I would like to receive emails announcing new innovations, services or special savings from Goodyear SightLine.</b-form-checkbox>
                </b-form-group>

                <b-form-group  class="form-buttons col-sm-12">
                  <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" sitekey="6LdzzzodAAAAAB2R9eTTGxpbzEx4zPxdMzF2Mzmc" ></vue-recaptcha>
                  <p class="red">{{this.invalidField}}</p>

                  <button type="submit" class="btn gy-primary" v-if="!loading">Contact Us</button>
                  <b-spinner label="Loading..." v-if="loading"></b-spinner>
                </b-form-group >
              </b-form>

            <div class="success col" v-if="showThanks && !showForm">
              <h3>Thank you for your message!</h3>
              <p>A Goodyear SightLine representative will be in touch. </p>
            </div>

            <div class="fail col" v-if="showError && !showForm">
              <h3>Error</h3>
              <p>Please try again later.</p>
            </div>
            </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
window.dataLayer = window.dataLayer || []
export default {
  name: 'Contact',
  data() {
      return {
        form: {
          firstName: '',
          lastName: '',
          email: '',
          iam: '',
          message: '',
          gResponse: ''
        },
        showForm: true,
        invalidField: ' ',
        showThanks: false,
        showError: false,
        loading: false,
        options: [

          { value: 'Automaker', text: 'Automaker' },
          { value: 'Autonomous System Provider', text: 'Autonomous System Provider' },
          { value: 'Interested in Tire Engagement Program', text: 'Interested in Tire Engagement Program' },
          { value: 'Light Cargo Vans Fleet', text: 'Light Cargo Vans Fleet' },
          { value: 'Other Fleet', text: 'Other Fleet' },
          { value: 'Press / Analyst', text: 'Press / Analyst' }
        ],
        testMsg: ''
      }
    },
    components : {
      VueRecaptcha
    },
    methods : {
      onVerify: function (response) {
        if (response) this.form.gResponse = response
      },
      submitContact (evt) {
        evt.preventDefault()
        this.loading = true
        this.invalidField = ''
        // recaptcha
        if (this.form.gResponse.length == 0) {
            // reCaptcha not verified
            this.loading = false
            this.invalidField = 'Please verify using reCAPTCHA.'
            return false;
        }

        axios.post('/api/message', {
          form: this.form,
          name: 'christy form'
        })
          .then(response => {
            // console.log(response.data)
            this.showForm = false
            this.loading = false
            if (response.data === 1) {
              this.showThanks = true
              window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Contact Us' })
            } else {
              this.showError = true
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact-section {
  padding-bottom: 50px;
}
.contact-section .container {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
}
h2 {
  margin-bottom: 18px;
}
.form-group {
  float: left;
}
.form-buttons {
  margin-bottom: 1.5rem;
  margin-top: 15px;
}
.form-control {
  border-radius: 0px;
  height: 45px;
  font-size: 18px
}
.success, .fail {
  font-style: italic;
  padding-top: 25px;
}
.success h3 {
  color: green;
  font-family: "Barlow", serif;
  font-weight: bold;
}
.fail h3 {
  color: red;
  font-family: "Barlow", serif;
  font-weight: bold;
}
</style>
