<template>
  <div class="Footer global-footer">
    <footer>
      <div class="wrapper-inner">
        <b-container>
          <b-row>
            <b-col sm="12" md="12" class="footer-desc text-center">
              <img class="footer-logo" src="../assets/images/goodyear-sightline-logo-white.png" alt="Goodyear SightLine logo"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="5" lg="6" class="terms text-left">
              <ul>
                <li><a class="footLink" href="/">Home</a></li>
                <li><a class="footLink" href="/fleets">Fleets</a></li>
                <li><a class="footLink" href="/automakers-suppliers">Automakers & Suppliers</a></li>
              </ul>
            </b-col>
            <b-col sm="12" md="7" lg="6" class="terms text-left">
              <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
              <a class="footLink" href="https://corporate.goodyear.com/us/en/more/terms-conditions-and-privacy-policy.html" target="_blank">Terms & Conditions and Privacy Policy</a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  color: #fff;
  min-height: 300px;
}
.wrapper-inner {
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
.footer-logo {
  -webkit-animation: bounce 1.5s infinite ease-in-out;
  animation: bounce 1.5s infinite ease-in-out;
}
@-webkit-keyframes bounce {
	0% {
		-webkit-transform:translateY(-2px);
		transform:translateY(-2px)
	}
	50% {
		-webkit-transform:translateY(2px);
		transform:translateY(2px)
	}
	100% {
		-webkit-transform:translateY(-2px);
		transform:translateY(-2px)
	}
}
@keyframes bounce {
	0% {
		-webkit-transform:translateY(-2px);
		transform:translateY(-2px)
	}
	50% {
		-webkit-transform:translateY(2px);
		transform:translateY(2px)
	}
	100% {
		-webkit-transform:translateY(-2px);
		transform:translateY(-2px)
	}
}
h3 {
  color: #fff;
  text-transform: none;
  letter-spacing: 1px;
}
a, .v-btn {
  color: #fff;
}
a {
  text-decoration: underline;
}
a:hover {
  color: #F9ED25;
}
ul {
  list-style: none;
}
ul li {
  padding: 5px 0px;
}
li .b-icon.bi {
  height: 20px;
  width: 20px;
}
.form-group {
  float: left;
}
.form-control, .custom-select {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 3px solid;
  border-image-source: -webkit-linear-gradient(45deg, rgba(249, 237, 37, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(45deg, rgb(249, 237, 37), rgb(241, 90, 38));
  border-image-slice: 1;
  /*background-color: transparent;
  color: #fff;*/
  border-radius: 0px;
}
.success, .fail {
  font-style: italic;
}
.success h5 {
  color: #F9ED25;
  font-family: "Barlow", serif;
  font-weight: bold;
}
.fail h5 {
  color: red;
  font-family: "Barlow", serif;
  font-weight: bold;
}
.footer-desc {
  margin-top: 25px;
  margin-bottom: 25px;
}
.footer-end {
  margin-top: 35px;
  font-size: 16px;
  color: #fff;
}

@media (max-width:550px) {
  .footer-logo {
    width: 90%
  }
}
@media (max-width: 768px) {
  .terms {
    text-align: center;
  }
  .locations {
    max-width: 400px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .footer-desc {
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .footer-end {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 110px;
  }
}

@media (min-width: 980px) {
  .footer-desc {
      padding-left: 40px;
      margin-bottom: 50px;
  }
}
</style>
