<template>
  <div class="fleets" role="main">
    <vue-headful title="Fleets | Goodyear SightLine" url="https://www.goodyearsightline.com/fleets" description="Find out how tire intelligence can help you minimize downtime and improve tire management"/>

    <div class="fleets-container">
      <section class="intro-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
        <b-container>
          <h1 class="wf-head">Fleets</h1>
          <h2>Customized to autonomous and traditional fleets' needs to help minimize downtime, improve tire management and increase operational efficiency</h2>
          <br/><br/>
        </b-container>
      </section>
      <b-container class="more-details">
        <section class="passcar-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">       
          <b-row>
            <b-col sm="12" md="5" lg="6">
              <img class="left" src="../assets/images/shared/taxi.jpg" alt="Taxi" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>  
            </b-col>
            <b-col sm="12" md="7" lg="6">
              <h2>Passenger / robotaxi</h2>
              <p>We can enable an optimised journey to operators, using vehicle-road related insights and providing alerts like leak detection, tire wear & risk of aquaplaning to (AV) fleets through APIs, a customized fleet dashboard or full integration in existing monitoring systems.</p>   
            
            </b-col>
          </b-row>    
        </section>

        <section class="lcv-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">          
          <b-row>
            <b-col sm="12" md="7" lg="6">
              <h2>Field Service & Last-mile delivery Industry Vehicles</h2>
              <p>Goodyear SightLine's suite of connected technologies includes a remote tire monitoring system for light-duty vehicle fleets serving the field service, construction and last-mile delivery industries.</p>   
              <div class="buttons">
                <button class="btn gy-primary gy-features" data-section="Last-mile delivery & field services" href="#" v-b-modal="'delivery-modal'">See Features </button>
              </div>
            </b-col>
            <b-col sm="12" md="5" lg="6">
              <img class="right" src="../assets/images/shared/ladder-on-truck.jpg" alt="Worker putting ladder on truck" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>     
            </b-col>
          </b-row>       
        </section>

        <section class="comm-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
          <b-row>
            <b-col sm="12" md="5" lg="6">
              <img class="left" src="../assets/images/shared/semi-trailer.jpg" alt="Semi Trailer Sunset" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>  
            </b-col>
            <b-col sm="12" md="7" lg="6">
              <h2>Tire Management for Commercial Fleets</h2>
              <p>TPMS Plus™ is a connected, active monitoring system that goes beyond the benefits of traditional dashboard monitoring. Using Goodyear's unique, fleet specific algorithms, TMPS Plus™ helps identify critical issues, inflation trends and much more allowing you to be proactive instead of reactive.</p>   
              <div class="buttons">
                <button class="btn gy-primary gy-features" data-section="Tire Management for Truck Fleets" href="#" v-b-modal="'comm-modal'">See Features </button>
                <a class="btn gy-primary gy-details" href="https://www.goodyeartrucktires.com/tire-management/tpms-plus.html" target="_blank">Get Details </a>
              </div>
            </b-col>
          </b-row>
        </section>
      </b-container>
      <div class="gy-separator last"></div>
      
      <Contact/>
    </div>

    <b-modal id="delivery-modal" :modal-class="'gy-feat-modal'" title="Last-mile delivery and field services" size="xl" ok-only>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <h4>Current Features:</h4>
            <ul>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Leak Detection </span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span>Tire Pressure Monitoring</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span>Parked Vehicle Tire Monitoring</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span>Tire Identification</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Mileage</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span>Treadwear </span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span>Auto-location</span></li>
            </ul>
          </div>
          <div class="col-md-12 col-lg-6">
              <h4>Anticipated Future Features:</h4>
              <ul>
                <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Battery Status</span></li>
                <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Load Estimation</span></li>
                <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Fuel Economy</span></li>
                <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Tire Identification</span></li>
                <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Road Friction</span></li>
              </ul>
          </div>
        </div>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>

    <b-modal id="comm-modal" :modal-class="'gy-feat-modal'" title="Tire Management for Truck Fleets" size="xl" ok-only>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <h4>Current Features:</h4>
            <ul>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Predictive pressure monitoring with leak detection</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Low Pressure Alerts</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> High Temperature alerts</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Vehicle Track and Trace</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Custom Geofence Alerting</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Inflation and hardware status reporting</span></li>
              <li><b-icon icon="circle-fill" class="float-left"></b-icon> <span> Standard APIs available for fleet maintenance integration</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>
  </div>
</template>

<script>
import Contact from './Shared/Contact'
export default {
  name: 'Fleets',
  components: {
    Contact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fleets-container {
  background-color: #f2f2f2;
}
h1 {
  padding-top: 100px;
}
h2 {
  font-size: 2.25rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
section {
  margin-bottom: 35px;
}
.container.more-details {
  background-color: #fff;
}
.passcar-section {
  padding-top: 20px;
}
.intro-section, .passcar-section, .lcv-section, .comm-section {
  padding-left: 15px;
  padding-right: 15px;
}
.intro-section {
  text-align: center;
  color: #fff;
  min-height: 550px;
  background-image: url('../assets/images/background/van-fleet-md.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
}
.intro-section h1 {
  margin: 0 auto;
}
.intro-section h2 {
  margin: 0 auto;
}
.intro-section .btn {
  margin: 10px 20px;
}
.details-section h2 {
  text-transform: capitalize;
}
.details-section .buttons {
  margin-top: 45px;
  margin-bottom: 20px;
}
.comm-section {
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.comm-section .buttons .btn {
    margin: 5px;
  }
img {
  padding: 7px 7px 20px;
  max-width: 100%;
}
.gy-feat-modal ul  {
  list-style: none;
  padding-left: 0px;
}
.gy-feat-modal ul li {
  position: relative;
  margin-left: 1.5em;
  text-transform: capitalize;
  font-size: 20px;
  min-height: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}
.gy-feat-modal ul li span {
  margin: 35px 0px 5px 30px;
}
.gy-feat-modal ul li .b-icon {
  position: absolute;
  left: -1.5em;
  top: 0.1em;
  font-size: 18px;
}
.fleets-container .contact-section {
  margin-bottom: 0px;
}

@media (max-width: 456px) {
  .intro-section {
    padding-bottom: 80px;
  }
}
@media (max-width: 500px) {
  .intro-section {
    background-image: url('../assets/images/background/van-fleet-xs.jpg');
  }
  .comm-section .buttons .btn:first-child {
    margin-bottom: 15px;
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .intro-section {
    background-image: url('../assets/images/background/van-fleet-sm.jpg');
  }
}
@media (max-width: 549px) {
  img {
    width: 100%;
    padding: 20px 0px 10px;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 3.5rem;
  }
  .details-section {
    margin-bottom: 45px;
  }
}
@media (max-width: 992px) {
  .comm-section {
    padding-bottom: 100px;
  }
  .comm-section .feat {
    margin-bottom: 50px;
  }
  .feat .btn {
    margin: 15px 5px;
  }
  .gy-separator.last {
    height: 60px;
    margin-bottom: 55px;
  }
}
@media (min-width: 1200px) {
  .intro-section h1 {
    width: 85%;
  }
  .intro-section h2 {
    width: 78%;
  }
  .comm-section .feat {
    margin-top: 60px;
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  .intro-section {
    background-image: url('../assets/images/background/van-fleet-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px ) {
  .intro-section {
    background-image: url('../assets/images/background/van-fleet-xl.jpg');
  }
}
@media (min-width: 2501px) {
  .intro-section {
    background-image: url('../assets/images/background/van-fleet.jpg');
  }
}
</style>
