import Vue from 'vue'
import Router from 'vue-router'

import AutomakersSuppliers from '@/components/AutomakersSuppliers'
import Fleets from '@/components/Fleets'
import Home from '@/components/Home'
import error404 from '@/components/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/automakers-suppliers',
      name: 'AutomakersSuppliers',
      component: AutomakersSuppliers
    },
    {
      path: '/fleets',
      name: 'Fleets',
      component: Fleets
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '*',
      redirect: '/404-error'
    },
    {
      path: '/automakers',
      redirect: '/automakers-suppliers'
    },
    {
      path: '/autonomous-systems',
      redirect: '/automakers-suppliers'
    }
  ]
})
