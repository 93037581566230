<template>
  <div class="automakers-suppliers" role="main">
    <vue-headful title="Automakers & Suppliers | Goodyear SightLine" url="https://www.goodyearsightline.com/automakers-suppliers" description="Find out how tire intelligence can help you enrich customer experience, enable performance and safety improvements of your ADAS and AD systems."/>

    <div class="automaker-supplier-container">
      <section class="intro-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
        <b-container>
          <h1 class="wf-head">Automakers & Suppliers</h1>
          <h2>Inform your customer</h2>
          <h2>Unlock performance and enhance safety with Goodyear SightLine</h2>
          <br/><br/>
        </b-container>
      </section>

      <section class="statement-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
        <b-row>
          <b-col sm="12" md="10" offset-md="1" lg="6" offset-lg="3">
            <h2 class="text-center">Goodyear SightLine technology offers value for the infotainment experience, vehicle control system improvement, autonomous and driving assistance systems.</h2>
          </b-col>
        </b-row>          
      </section>

      <b-container class="more-details">
        <section class="driver-exp-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
          <b-row>
            <b-col sm="12" md="5" lg="6">
              <img src="../assets/images/shared/person-driving.jpg" alt="Person driving" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>  
            </b-col>
            <b-col sm="12" md="7" lg="6">
              <h2>Driver Experience</h2>
              <p>Access to tire-road related information at the right time and place to create a seamless user experience</p>   
              <div class="buttons">
                <button class="btn gy-primary gy-features" data-section="Driver Experience" v-b-modal="'driver-exp-modal'" @click="resetAccordionStatus()">See Features </button>
              </div>
            </b-col>
          </b-row>          
        </section>

        <section class="safety-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">        
          <b-row>
            <b-col sm="12" md="7" lg="6">
              <h2>Performance and Safety</h2>
              <p>Enhanced performance and safety features through predictive and real-time input to vehicle control systems</p>   
              <div class="buttons">
                <button class="btn gy-primary gy-features" data-section="Performance and Safety" v-b-modal="'perform-safety-modal'" @click="resetAccordionStatus()">See Features </button>
              </div>
            </b-col>
            <b-col sm="12" md="5" lg="6">
              <img src="../assets/images/shared/car-technology.jpg" alt="Car Technology" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>     
            </b-col>
          </b-row>   
        </section>

        <section class="eco-opt-eff-section details-section" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
          <b-row>
            <b-col sm="12" md="5" lg="6">
              <img src="../assets/images/shared/car-charging.jpg" alt="Car Charging" data-aos="fade-left" data-aos-delay="300" data-aos-once="true"/>  
            </b-col>
            <b-col sm="12" md="7" lg="6">
              <h2>Eco Optimizer</h2>
              <p>Enhanced energy management for all type of vehicles through intelligent tire insights</p>   
              <div class="buttons">
                <button class="btn gy-primary gy-features" data-section="Eco Optimizer" v-b-modal="'eco-opt-modal'" @click="resetAccordionStatus()">See Features </button>
              </div>
            </b-col>
          </b-row>
        </section>

      </b-container>
      <div class="gy-separator"></div>
      
      <Contact/>
    </div>

    <b-modal id="driver-exp-modal" :modal-class="'gy-feat-modal'" title="Driver Experience Package" size="xl" @show="updateOpenModalId('driver-exp-modal')">
      <div class="modal-body">
        <div class="row">
          <div class="gy-accordions col-12 col-lg-6">

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-1 variant="info" @click="updateSelectedImage(1, 0)">
                  Aquaplaning Alert
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[0]" id="accordion-1" accordion="driver-exp-accordion" role="tabpanel">
                <b-card-body>
                  <p>Our solution can offer advanced aquaplaning alerts and provide drivers with essential information to navigate wet roads in a smarter way.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-2 variant="info" @click="updateSelectedImage(2, 0)">
                  Low Grip Alert
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[1]" id="accordion-2" accordion="driver-exp-accordion" role="tabpanel">
                <b-card-body>
                  <p>The low grip alert feature is informing drivers when low grip situations occur, so that they may adapt their driving style to ensure a more confident drive.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-3 variant="info" @click="updateSelectedImage(3, 0)">
                  Tire Wear Indicator
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[2]" id="accordion-3" accordion="driver-exp-accordion" role="tabpanel">
                <b-card-body>
                  <p>Our tire wear indicator offers information on the condition of the vehicle's tires, ensuring that drivers can have a predictive approach to tire maintenance and a seamless tire replacement process.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-4 variant="info" @click="updateSelectedImage(4, 0)">
                  Tire leak detection
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[3]" id="accordion-4" accordion="driver-exp-accordion" role="tabpanel">
                <b-card-body>
                  <p>The leak detection feature allows drivers to detect and address tire leaks early, preventing interrupted journeys.</p>
                  <p>By identifying and fixing leaks promptly, this feature can extend the lifespan of tires, potentially saving money in the long run.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

          </div>

          <div class="desktop-img col-lg-6 text-center d-lg-block d-none">
            <img :src="selectedImage.src" :alt="selectedImage.alt" class="img-fluid" />
          </div>
        </div>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>

    <b-modal id="perform-safety-modal" :modal-class="'gy-feat-modal'" title="Performance and Safety Package" size="xl" @show="updateOpenModalId('perform-safety-modal')">
      <div class="modal-body">
        <div class="row">
          <div class="gy-accordions col-12 col-lg-6">

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-1 variant="info" @click="updateSelectedImage(1)">
                  Aquaplaning prediction and detection
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[0]" id="accordion-1" accordion="perform-safety-accordion" role="tabpanel">
                <b-card-body>
                  <p>Our solution can predict upcoming risk of aquaplaning or detect real-time aquaplaning events, so that the vehicle control system can adapt to the situation and bring the vehicle back in a more stable state.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.accordion-2 variant="info" @click="updateSelectedImage(2)">
                  Friction estimation
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[1]" id="accordion-2" accordion="perform-safety-accordion" role="tabpanel">
                <b-card-body>
                  <p>Make the vehicle's control systems friction-adaptive. Having the knowledge of the grip potential of tires on a given road surface can improve the performance of automated emergency braking or adaptive cruise control. For autonomous systems, our solution can serve as redundancy for other parts of the perception stack.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" >
                <b-button block href="#" v-b-toggle.accordion-3 variant="info" @click="updateSelectedImage(3)">
                  Adaptive tire model
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[2]" id="accordion-3" accordion="perform-safety-accordion" role="tabpanel">
                <b-card-body>
                  <p>Help deliver best-in-class vehicle handling and NVH (noise vibration harshness) when your vehicle actuators can optimise their behaviour using the adaptive tire solution.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" >
                <b-button block href="#" v-b-toggle.accordion-4 variant="info" @click="updateSelectedImage(4)">
                  Tire Load Estimation
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[3]" id="accordion-4" accordion="perform-safety-accordion" role="tabpanel">
                <b-card-body>
                  <p>Having an accurate measure of the load at each corner of the vehicle can bring the next level of control system optimization, such as stability control or load-adjusted inflation.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" >
                <b-button block href="#" v-b-toggle.accordion-5 variant="info" @click="updateSelectedImage(5)">
                  Tire wear estimation
                  <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                  <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                </b-button>
              </b-card-header>
              <b-collapse v-model="accordionStatus[4]" id="accordion-5" accordion="perform-safety-accordion" role="tabpanel">
                <b-card-body>
                  <p>As tire performances may be changing with different levels of wear, it is crucial for control systems to know the tire wear state and adapt for changes to keep the same level of performance throughout the tire life.</p>
                  <p>For autonomous vehicles, the information about the tire state can ensure a reliable journey.</p>
                  <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                </b-card-body>
              </b-collapse>
            </b-card>

          </div>

          <div class="desktop-img col-lg-6 text-center d-lg-block d-none">
            <img :src="selectedImage.src" :alt="selectedImage.alt" class="img-fluid" />
          </div>
        </div>
      </div>
      <div slot="modal-footer">
      </div>
    </b-modal>

    <b-modal id="eco-opt-modal" :modal-class="'gy-feat-modal'" title="Eco Optimizer Package" size="xl" @show="updateOpenModalId('eco-opt-modal')">
      <div class="modal-body">
        <div class="row">
          <div class="gy-accordions col-12 col-lg-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-1 variant="info" @click="updateSelectedImage(1)">
                    Tire Energy Consumption
                    <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                    <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse v-model="accordionStatus[0]" id="accordion-1" accordion="eco-opt-accordion" role="tabpanel">
                  <b-card-body>
                    <p>Our solution incorporates the relevant driving conditions to deliver real-time information about energy consumption of the vehicle's tires. This input serves to perform energy efficient route planning and predict EV range more accurately.</p>
                    <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" >
                  <b-button block href="#" v-b-toggle.accordion-2 variant="info" @click="updateSelectedImage(2)">
                    Tire Wear Estimation
                    <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                    <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse v-model="accordionStatus[1]" id="accordion-2" accordion="eco-opt-accordion" role="tabpanel">
                  <b-card-body>
                    <p>Our tire wear estimator identifies the current wear state of each individual tire, which helps to assess evolving rolling resistance to make efficient range estimations.</p>
                    <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" >
                  <b-button block href="#" v-b-toggle.accordion-3 variant="info" @click="updateSelectedImage(3)">
                    Tire Load State
                    <b-icon icon="chevron-up" class="when-open float-right"></b-icon>
                    <b-icon icon="chevron-down" class="when-closed float-right"></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse v-model="accordionStatus[2]" id="accordion-3" accordion="eco-opt-accordion" role="tabpanel">
                  <b-card-body>
                    <p>Have an accurate measure of the load at each corner of the vehicle, delivered by the tire load estimator. This serves as input for EV range estimation and powertrain energy optimization.</p>
                    <img :src="selectedImage.src" :alt="selectedImage.alt" class="mobile-img img-fluid" />
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="desktop-img col-lg-6 text-center d-lg-block d-none">
            <img :src="selectedImage.src" :alt="selectedImage.alt" class="img-fluid" />
          </div>
        </div>
      </div>

      <div slot="modal-footer">
      </div>
    </b-modal>

  </div>
</template>

<script>
import Contact from './Shared/Contact'
export default {
  name: 'AutomakersSuppliers',
  components: {
    Contact
  },
  data () {
    return {
      openModalId: null,
      selectedImage: {
        src: './images/goodyear-sightline-logo.jpg',
        alt: 'Goodyear SightLine Logo'
      },
      driverExpAccordions: {
        0: { src: './images/packages/driver-exp-package.png', alt: 'Driver Experience Package' },
        1: { src: './images/packages/driver-exp-aquaplaning.png', alt: 'Driver Experience Package Aquaplaning' },
        2: { src: './images/packages/driver-exp-low-grip.png', alt: 'Driver Experience Package Low Grip Alert' },
        3: { src: './images/packages/driver-exp-tire-wear.png', alt: 'Driver Experience Package Tire Wear Detection' },
        4: { src: './images/packages/driver-exp-leak-detection.png', alt: 'Driver Experience Package Tire Leak Detection' }
      },
      performSafetyAccordions: {
        0: { src: './images/packages/perform-safety-package.png', alt: 'Performance and Safety Package' },
        1: { src: './images/packages/perform-safety-aquaplaning.png', alt: 'Performance and Safety Package Aquaplaning' }, 
        2:  { src: './images/packages/perform-safety-friction.png', alt: 'Performance and Safety Package Friction Estimation' },
        3:  { src: './images/packages/perform-safety-adaptive-tire-model.png', alt: 'Performance and Safety Package Adaptive Tire Model' },
        4:  { src: './images/packages/perform-safety-tire-load.png', alt: 'Performance and Safety Package Tire Load Estimation' },
        5:  { src: './images/packages/perform-safety-tire-wear.png', alt: 'Performance and Safety Package Tire Wear Estimation' }
      },
      ecoOptAccordions: {
        0: { src: './images/packages/eco-opt-package.png', alt: 'Eco Optimizer Package' },
        1: { src: './images/packages/eco-opt-package-tire-consumption.png', alt: 'Eco Optimizer Package Tire Energy Consumption' },
        2: { src: './images/packages/eco-opt-package-tire-wear.png', alt: 'Eco Optimizer Package Tire Wear Estimation' },
        3: { src: './images/packages/eco-opt-package-tire-load.png', alt: 'Eco Optimizer Package Tire Load State' }
      },
      accordionStatus: [], // Array to track accordion states (open/close)
    }
  },
  methods: {
    updateSelectedImage (accordionNumber) {
      // set driver-exp-modal accordion init
      let modalAccordion = this.driverExpAccordions;
      
      if (this.openModalId == 'perform-safety-modal') {
        // use perform-safety-modal accordion
        modalAccordion = this.performSafetyAccordions;
      } else if (this.openModalId == 'eco-opt-modal') {
        // use eco-opt-modal accordion
        modalAccordion = this.ecoOptAccordions;
      }

      // set to matching accordion & default image if no match
      return this.selectedImage = modalAccordion[accordionNumber] || { src: '', alt: '' };

    },
    allAccordionsClosed() {
      // checks if all accordions are closed
      return this.accordionStatus.every(acc => !acc); 
    },
    updateOpenModalId(modalId) {
      this.openModalId = modalId;
    },
    resetAccordionStatus () {
      // reset accordion status
      this.accordionStatus = []
    }
  },
  watch: {
    accordionStatus: {
      handler() {
        // when accordions change, update images
        if (this.allAccordionsClosed()) {
          // all accordions are collapsed - set default image
          this.updateSelectedImage(0);
        }

      },
      deep: true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.automaker-supplier-container {
  background-color: #f2f2f2;
}
h1 {
  padding-top: 100px;
}
h2 {
  font-size: 2.25rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
section {
  margin-bottom: 35px;
}
.container.more-details {
  background-color: #fff;
}
.intro-section, .details-section {
  padding-left: 15px;
  padding-right: 15px;
}
.intro-section {
  text-align: center;
  color: #fff;
  min-height: 550px;
  background-image: url('../assets/images/background/vehicles-snow-md.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.intro-section h1 {
  margin: 0 auto;
}
.intro-section h2 {
  margin: 0 auto;
}
.intro-section .btn {
  margin: 10px 20px;
}
.details-section .container {
  padding-left: 30px;
}
.details-section .buttons {
  margin-top: 45px;
  margin-bottom: 20px;
}
.statement-section h2 {
  font-size: 28px;
}
.driver-exp-section {
  padding-top: 20px;
}
.eco-opt-eff-section {
  padding-bottom: 10px;
  margin-bottom: 0px;
}
img {
  padding: 7px 7px 20px;
  max-width: 100%;
}
.automaker-supplier-container .contact-section {
  margin-bottom: 0px;
}

@media (max-width: 500px) {
  .intro-section {
    background-image: url('../assets/images/background/vehicles-snow-xs.jpg');
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .intro-section {
    background-image: url('../assets/images/background/vehicles-snow-sm.jpg');
  }
}
@media (max-width: 549px) {
  img {
    width: 100%;
    padding: 0px 0px 10px;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 3.5rem;
  }
  .details-section {
    margin-bottom: 45px;
  }
}
@media (max-width: 992px) {
  .contact-section .subheader::before {
    margin-top: -190px;
    height: 190px;
  }
  .eco-opt-eff-section {
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .mobile-img {
    display: none;
  }
}
@media (min-width: 1200px) {
  .intro-section h1 {
    width: 85%;
  }
  .feat {
    margin-top: 60px;
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  .intro-section {
    background-image: url('../assets/images/background/vehicles-snow-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px ) {
  .intro-section {
    background-image: url('../assets/images/background/vehicles-snow-xl.jpg');
  }
}
@media (min-width: 2501px) {
  .intro-section {
    background-image: url('../assets/images/background/vehicles-snow.jpg');
  }
}
</style>
