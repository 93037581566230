<template>
  <div class="home" role="main">
    <vue-headful title="Goodyear SightLine" description="Goodyear SightLine is Goodyear's new suite of tire intelligence solutions that help enable seamless, safe and reliable mobility for drivers, vehicles, operators and automakers."/>
    <section class="intro-section">
        <b-container>
          <div data-aos="zoom-in">
            <img class="wingfoot" src="../assets/images/goodyear-wingfoot.svg" alt="Goodyear wingfoot icon"/>
             <h1 class="wf-head">SEE BEYOND <br/> TIRES</h1>
          </div>
        </b-container>
    </section>
    
    <About/>
    <Vision/>
    <Applications/>
    <Experience/>
    
    <Contact/>

    <div class="gy-scroll">
      <a class="btn gy-dk" onclick="window.scrollTo(0, 0)"><b-icon-arrow-up></b-icon-arrow-up> To Top </a>
    </div>
  </div>
</template>

<script>
import About from './Shared/About'
import Vision from './Shared/Vision'
import Experience from './Shared/Experience'
import Applications from './Shared/Applications'
import Contact from './Shared/Contact'
export default {
  name: 'Home',
  components: {
    About, Vision, Experience, Applications, Contact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  background-color: #F2F2F2;
}
/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  /*position: fixed;*/
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  /*border: 3px solid red;*/
}

/* Add some content at the bottom of the video/page */
.content {
  /*position: fixed;
  bottom: 266px;
  background: rgba(0, 0, 0, 0.5);*/
  width: 100%;
  padding: 20px;
}


.intro-section {
  min-height: 650px;
  padding-top: 100px;
  color: #fff;
  background-color: transparent;
  background-color: #6D6050;
  background-image: url('../assets/images/background/video-still-v2-md.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.wingfoot {
  height: 65px;
}
h1 {
  font-size: 4.0rem;
  margin-bottom: 25px;
}
@media (max-width: 500px) {
  .intro-section {
    background-image: url('../assets/images/background/video-still-v2-xs.jpg');
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .intro-section {
    background-image: url('../assets/images/background/video-still-v2-sm.jpg');
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 5.25rem;
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  .intro-section {
    background-image: url('../assets/images/background/video-still-v2-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px ) {
  .intro-section {
    background-image: url('../assets/images/background/video-still-v2-xl.jpg');
  }
}
@media (min-width: 2501px) {
  .intro-section {
    background-image: url('../assets/images/background/video-still-v2.jpg');
  }
}
</style>
