import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import AOS from 'aos'
import vueHeadful from 'vue-headful'
import App from './App.vue'
import router from './router'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/aos/dist/aos.css'
import '@/assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('vue-headful', vueHeadful)


new Vue({ el: '#app', router, created () {
  AOS.init()
}, render: h => h(App) })

/*new Vue({
  render: h => h(App),
}).$mount('#app')*/
