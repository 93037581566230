<template>
  <section class="experience-section">
      <b-container>
        <div class="section-background"></div>
        <b-row>
          <b-col sm="12">
            <h2 class="wf-head" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">Experience Beyond Tires</h2>
            <img class="tire" src="../../assets/images/background/tire-mobile.jpg" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="9" sm="8" md="5">
            <div class="card" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <h3 class="card-title">Anticipatory</h3>
                <p class="card-text">We plug into systems' elements so we can help identify which issues will arise before they happen, and then proactively alert you so you can stay ahead. </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="9" offset="3" sm="8" offset-sm="4" md="5" offset-md="7">
            <div class="card" data-aos="fade-left" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <h3 class="card-title">Actionable</h3>
                <p class="card-text">We cut through the noise by translating data into insights and then by helping enable customers to turn those insights into actions. </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="9" sm="8" md="5">
            <div class="card" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <h3 class="card-title">Aware</h3>
                <p class="card-text">We understand our context at both the micro and macro level — whether that's measuring the air pressure in the tire, or playing a role in the greater vehicle and mobility story.</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'Experience'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.experience-section {
  margin-bottom: 25px;
}
.container {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;
  background-image: url('../../assets/images/background/tire.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 550px;
}
h2 {
  margin-bottom: 50px;
  font-size: 2.25rem;
}
.tire {
  display: none;
  margin: 0 auto;
}
h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
.card {
  border-radius: 0px;
  background: rgba(255,255,255, .85);
  color: #000;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .container {
    background-image: none;
    background-color: #fff;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 1.75rem;
  }
  .tire {
    display: block;
  }
}
</style>
