<template>
  <section class="about-section">
      <b-container>
        <b-row>
          <b-col>
            <span id="about" class="subheader"></span>
          </b-col>
        </b-row>
        <b-row >
          <b-col class="px-0" sm="12" md="4">
            <div class="card" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <div><img class="icon" src="../../assets/images/icons/sl-all-season-tire.svg" alt="All season icon" /></div>
                <h2 class="card-title">Never stop <br/>moving the world</h2>
                <p class="card-text">A suite of tire intelligence technologies that can help identify and address problems before they become visible, detecting sensing conditions in real time </p>
              </div>
            </div>
          </b-col>
          <b-col class="px-0" sm="12" md="4">
            <div class="card" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <div><img class="icon" src="../../assets/images/icons/sl-bar-chart.svg" alt="Bar chart icon" /></div>
                <h2 class="card-title">Turn data<br/> into action</h2>
                <p class="card-text">Communicate tire health and in the future, road conditions to drivers, vehicles, operators and municipalities, enhancing uptime and safety along the way</p>
              </div>
            </div>
          </b-col>
          <b-col class="px-0" sm="12" md="4">
            <div class="card" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <div class="card-body">
                <div><img class="icon" src="../../assets/images/icons/sl-summer-tire.svg" alt="Summer icon" /></div>
                <h2 class="card-title">Let life<br/> take the focus</h2>
                <p class="card-text">Connected technology that helps enable a reliable and seamless journey to make life's connections easier every day</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-section {
  margin-bottom: 25px;
  margin-top: -65px;
}
.card {
  text-align: left;
  border-radius: 0px;
  border-color: #CDCDCD;
}
.icon {
  height: 1em;
  font-size: 2rem;
}
@media (min-width: 768px) and (max-width: 992px) {
  .card {
    min-height: 392px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .card {
    min-height: 315px;
  }
}
@media (min-width: 1200px) {
  .card {
    min-height: 287px;
  }
}
</style>
